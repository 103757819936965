import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryLayout from "../layout/PrimaryLayout";
import { DataPerRangeDay } from "../dashboard/DateRangePickr";
import axios from "axios";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { fetchInitialData, sumReports } from "../../utilis/api";
import SUmUp from "../summary/SUmUp";

export const options = (lastDays) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Last ${lastDays} days`,
      },
    },
  };
};

export const optionsPie = (datas) => {
  const data = datas[0].date;
  const formattedDate = moment(data).format("MMM DD YYYY");

  return {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: formattedDate,
      },
    },
  };
};

export const dataGHG = (datas) => {
  return {
    labels: datas.map((item) => moment(item.date).format("MMM DD, YYYY")),
    datasets: [
      {
        label: "CO2",
        data: datas.map((item) => item.CO2),
        borderColor: "#2D4EFF",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        fill: false, // Set to false to remove the area below the line
        pointRadius: 0, // Set to 0 to remove data points (dots)
        lineTension: 0.2, // Adjust the tension for a smoother line
        borderWidth: 2, // Adjust the width of the line
      },
      {
        label: "N2O",
        data: datas.map((item) => item.N2O),
        borderColor: "#E71818",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: false, // Set to false to remove the area below the line
        pointRadius: 0, // Set to 0 to remove data points (dots)
        lineTension: 0.2, // Adjust the tension for a smoother line
        borderWidth: 2, // Adjust the width of the line
      },
      {
        label: "CH4",
        data: datas.map((item) => item.CH4),
        borderColor: "#C014DC",
        backgroundColor: "rgba(224, 181, 217, 0.5)",
        fill: false, // Set to false to remove the area below the line
        pointRadius: 0, // Set to 0 to remove data points (dots)
        lineTension: 0.2, // Adjust the tension for a smoother line
        borderWidth: 2, // Adjust the width of the line
      },
      {
        label: "HCNM",
        data: datas.map((item) => item.HCNM),
        borderColor: "#119D1F",
        backgroundColor: "rgba(11, 136, 94, 0.5)",
        fill: false, // Set to false to remove the area below the line
        pointRadius: 0, // Set to 0 to remove data points (dots)
        lineTension: 0.2, // Adjust the tension for a smoother line
        borderWidth: 2, // Adjust the width of the line
      },
    ],
  };
};
export const data = (datas) => {
  return {
    labels: datas.map((item) => moment(item.date).format("MMM DD, YYYY")),
    datasets: [
      {
        label: "Combustion",
        data: datas.map((item) => item.combustion),
        borderColor: "#2D4EFF",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        fill: false, // Set to false to remove the area below the line
        pointRadius: 0, // Set to 0 to remove data points (dots)
        lineTension: 0.2, // Adjust the tension for a smoother line
        borderWidth: 2, // Adjust the width of the line
      },
      {
        label: "Flaring",
        data: datas.map((item) => item.flaring),
        borderColor: "#E71818",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: false, // Set to false to remove the area below the line
        pointRadius: 0, // Set to 0 to remove data points (dots)
        lineTension: 0.2, // Adjust the tension for a smoother line
        borderWidth: 2, // Adjust the width of the line
      },
      {
        label: "Fugitives",
        data: datas.map((item) => item.fugitives),
        borderColor: "#C014DC",
        backgroundColor: "rgba(224, 181, 217, 0.5)",
        fill: false, // Set to false to remove the area below the line
        pointRadius: 0, // Set to 0 to remove data points (dots)
        lineTension: 0.2, // Adjust the tension for a smoother line
        borderWidth: 2, // Adjust the width of the line
      },
      {
        label: "Venting",
        data: datas.map((item) => item.venting),
        borderColor: "#119D1F",
        backgroundColor: "rgba(11, 136, 94, 0.5)",
        fill: false, // Set to false to remove the area below the line
        pointRadius: 0, // Set to 0 to remove data points (dots)
        lineTension: 0.2, // Adjust the tension for a smoother line
        borderWidth: 2, // Adjust the width of the line
      },
    ],
  };
};
const Highlights = () => {
  const currentDate = moment().format("YYYY-MM-DD");
  const lastMonthDate = moment(currentDate).subtract(1, "month");
  const formattedLastMonthDate = lastMonthDate.format("YYYY-MM-DD");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingsourceRange, setLoadingsourceRange] = useState(false);
  const [sourceRange, setSourceRange] = useState();
  const [loadingghgRange, setLoadingghgRange] = useState(false);
  const [ghgRange, setGhgRange] = useState();
  const [ghgDay, setGhgDay] = useState();
  const [source, setSource] = useState();
  const navigate = useNavigate();
  const [totals, setTotals] = useState({
    totalGhg: 0,
    totalSource: 0,
  });
  const [sumReportAdd, setSumReportAdd] = useState();
  const [emissions, setemissions] = useState({
    totalCO2: 0,
    totalCH4: 0,
    totalHCNM: 0,
    totalN2O: 0,
    totalCombustion: 0,
    totalFlaring: 0,
    totalFugitives: 0,
    totalVenting: 0,
  });

  const fetchDataByRange = async (type, start, end) => {
    if (type === "ghg") {
      try {
        setLoadingghgRange(true);
        const response = await axios.get(
          `fetchData/data-by-date-range/${type}/${start}/${end}`
        );
        setGhgRange(response.data);
        setemissions((prevState) => ({
          ...prevState,
          totalCO2: response.data.reduce(
            (accumulator, data) => accumulator + data.CO2,
            0
          ),
          totalCH4: response.data.reduce(
            (accumulator, data) => accumulator + data.CH4,
            0
          ),
          totalHCNM: response.data.reduce(
            (accumulator, data) => accumulator + data.HCNM,
            0
          ),
          totalN2O: response.data.reduce(
            (accumulator, data) => accumulator + data.N2O,
            0
          ),
        }));
      } catch (error) {
        console.error("Error fetching data by date range: ", error);
      } finally {
        setLoadingghgRange(false);
      }
    } else {
      try {
        setLoadingsourceRange(true);
        // const sumResponse = await axios.get(`fetchData/sumreport`);
        // console.log(sumResponse);
        const response = await axios.get(
          `fetchData/data-by-date-range/${type}/${start}/${end}`
        );

        setSourceRange(response.data);
        setemissions((prevState) => ({
          ...prevState,
          totalCombustion: response.data.reduce(
            (accumulator, data) => accumulator + data.combustion,
            0
          ),
          totalFlaring: response.data.reduce(
            (accumulator, data) => accumulator + data.flaring,
            0
          ),
          totalFugitives: response.data.reduce(
            (accumulator, data) => accumulator + data.fugitives,
            0
          ),
          totalVenting: response.data.reduce(
            (accumulator, data) => accumulator + data.venting,
            0
          ),
        }));
      } catch (error) {
        console.error("Error fetching data by date range: ", error);
      } finally {
        setLoadingsourceRange(false);
      }
    }
  };

  useEffect(() => {
    // Update totals when emissions change
    setTotals((prevTotals) => ({
      ...prevTotals,
      totalSource:
        emissions.totalCombustion +
        emissions.totalFlaring +
        emissions.totalFugitives +
        emissions.totalVenting,
    }));
    setTotals((prevState) => ({
      ...prevState,
      totalGhg:
        emissions.totalCO2 +
        emissions.totalCH4 +
        emissions.totalHCNM +
        emissions.totalN2O,
    }));
  }, [emissions]); // Run this effect whenever emissions change

  useEffect(() => {
    sumReports()
      .then((responseData) => {
        setSumReportAdd(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      });
    fetchDataByRange("source", formattedLastMonthDate, currentDate)
    fetchDataByRange("ghg", formattedLastMonthDate, currentDate)
    setIsLoading(false);
    // fetchInitialData()
    //   .then((responseData) => {
    //     setGhgDay(responseData[0]);
    //     setGhgRange(responseData[1]);
    //     setSource(responseData[2]);
    //     setSourceRange(responseData[3]);
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data: ", error);
    //     setIsLoading(false);
    //   });
  }, []);
  //  console.log(sumReportAdd)
  const rows = [
    {
      icon: "/combustion.svg",
      label: "Combustion",
      value: emissions.totalCombustion,
      status: "normal",
    },
    {
      icon: "/flaring.svg",
      label: "Flaring",
      value: emissions.totalFlaring,
      status: "normal",
    },
    {
      icon: "/fugitive.svg",
      label: "Fugitive",
      value: emissions.totalFugitives,
      status: "normal",
    },
    {
      icon: "/venting.svg",
      label: "Venting",
      value: emissions.totalVenting,
      status: "normal",
    },
  ];
  const gases = [
    {
      label: "Carbon dioxide",
      icon: "/co2.svg",
      value: emissions.totalCO2,
      status: "normal",
    },
    {
      label: "Nitrous oxide",
      icon: "/n2o.svg",
      value: emissions.totalN2O,
      status: "normal",
    },
    {
      label: "Methane ",
      icon: "/ch4.svg",
      value: emissions.totalCH4,
      status: "normal",
    },
    {
      label: "Hydrocarbon non-methane",
      icon: "/hcnm.svg",
      value: emissions.totalHCNM,
      status: "normal",
    },
  ];
  const progressBarData = [
    { color: "#2D4EFF", emissionType: emissions.totalCombustion },
    { color: "#E71818", emissionType: emissions.totalFlaring },
    { color: "#C014DC", emissionType: emissions.totalFugitives },
    { color: "#119D1F", emissionType: emissions.totalVenting },
  ];
  const emissionProgressBarData = [
    { color: "#2D4EFF", emissionType: emissions.totalCO2 },
    { color: "#E71818", emissionType: emissions.totalN2O },
    { color: "#C014DC", emissionType: emissions.totalCH4 },
    { color: "#119D1F", emissionType: emissions.totalHCNM },
  ];
  return (
    <PrimaryLayout>
      <div className="overflow-x-hidden">
        <SUmUp />

        <div className=" flex flex-wrap items-center gap-24 px-12 md:px-72 py-8 ">
          {" "}
          <div className="flex-1 flex-grow   ">
            <div className="flex justify-between align-center mx-1 mb-2 flex-wrap ">
              <p className="header-boxs header-large mb-1">
                {" "}
                Inquiry emission by source
              </p>
              <div className="mb-1" style={{ height: "32px" }}>
                <DataPerRangeDay type={"source"} fetchData={fetchDataByRange} />
              </div>
            </div>
            <div
              className=" "
              style={{
                backgroundColor: "#EEF0F2",
                padding: "20px",
                border: "1px solid #EEF0F2",
                borderRadius: "6px",
              }}
            >
              {" "}
              <div
                className="flex items-center justify-center  overflow-x-scroll "
                style={{
                  minHeight: "200px",
                  backgroundColor: "#ffffff",
                  border: "1px solid #EEF0F2",
                  borderRadius: "8px",
                }}
              >
                {isLoading ? (
                  <p className="mt-12">Loading...</p>
                ) : loadingsourceRange ? (
                  <p className="mt-12">Loading ...</p>
                ) : sourceRange.length !== 0 ? (
                  <Line
                    options={options(sourceRange.length)}
                    data={data(sourceRange)}
                  />
                ) : (
                  <div className="flex no lw-gray">
                    {" "}
                    <img className="mr-2" src="/no.svg" alt="emm" /> No data to
                    show for this range.
                  </div>
                )}
              </div>
              <div
                className="flex gap-1 bg-white "
                style={{
                  margin: "12px auto",
                  padding: "6px 12px",
                  border: "1px solid #EEF0F2",
                  borderRadius: "8px",
                }}
              >
                {progressBarData.map((data, index) => (
                  <ProgressBar
                    totalSource={totals.totalSource}
                    key={index}
                    color={data.color}
                    value={data.emissionType}
                    totals={totals}
                    emissionType={data.emissionType}
                  />
                ))}
              </div>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid #EEF0F2",
                  borderRadius: "8px",
                  paddingBottom: "64px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
              >
                {" "}
                <table className="min-w-full ">
                  <thead className="">
                    <tr className=" ">
                      {headers.map((header, index) => (
                        <TableHeader key={index} label={header} />
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={index}
                        icon={row.icon}
                        label={row.label}
                        value={row.value}
                        total={row.value}
                        totalSource={totals.totalSource}
                        status={row.status}
                      />
                    ))}

                    {/* Add more rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex-1 flex-grow    ">
            <div className="flex justify-between align-center mx-1 mb-2 flex-wrap gap-4 ">
              <p className="header-boxs  header-large mb-1">
                {" "}
                Inquiry emission by GHG species
              </p>
              <div className="mb-1" style={{ height: "32px" }}>
                <DataPerRangeDay type={"ghg"} fetchData={fetchDataByRange} />
              </div>
            </div>

            <div
              className=""
              style={{
                backgroundColor: "#EEF0F2",
                padding: "20px",
                border: "1px solid #EEF0F2",
                borderRadius: "6px",
              }}
            >
              {" "}
              <div
                className="flex items-center justify-center w-full overflow-x-hidden"
                style={{
                  minHeight: "200px",
                  backgroundColor: "#ffffff",
                  border: "1px solid #EEF0F2",
                  borderRadius: "8px",
                }}
              >
                {" "}
                {isLoading ? (
                  <p className="mt-12">Loading...</p>
                ) : loadingghgRange ? (
                  <p className="mt-12">Loading ...</p>
                ) : ghgRange.length !== 0 ? (
                  <Line
                    options={options(ghgRange.length)}
                    data={dataGHG(ghgRange)}
                  />
                ) : (
                  <div className="flex no lw-gray">
                    {" "}
                    <img className="mr-2" src="/no.svg" alt="emm" /> No data to
                    show for this range.
                  </div>
                )}
              </div>
              <div
                className="flex gap-1 bg-white "
                style={{
                  margin: "12px auto",
                  padding: "6px 12px",
                  border: "1px solid #EEF0F2",
                  borderRadius: "8px",
                }}
              >
                {emissionProgressBarData.map((data, index) => (
                  <ProgressBar
                    totalSource={totals.totalGhg}
                    key={index}
                    color={data.color}
                    value={data.emissionType}
                    totals={totals}
                    emissionType={data.emissionType}
                  />
                ))}
              </div>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid #EEF0F2",
                  borderRadius: "8px",
                  paddingBottom: "64px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
              >
                {" "}
                <table className="min-w-full ">
                  <thead className="">
                    <tr className=" ">
                      {headers.map((header, index) => (
                        <TableHeader key={index} label={header} />
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {gases.map((row, index) => (
                      <TableRow
                        key={index}
                        icon={row.icon}
                        label={row.label}
                        value={row.value}
                        total={row.value}
                        totalSource={totals.totalGhg}
                        status={row.status}
                      />
                    ))}
                    {/* Add more rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  );
};

const TableRow = ({ icon, label, value, total, totalSource, status }) => (
  <tr className="">
    <td className="  border-b " style={{ padding: "4px" }}>
      <div className=" flex justify-between">
        <p className="flex flex-wrap">
          <img className="mr-2 " src={icon} alt={label} />
          <span className="no c-gray">{label}</span>
        </p>
        <p className="separate">|</p>
      </div>
    </td>
    <td className="  border-b " style={{ padding: "4px" }}>
      <div className=" flex justify-between">
        <p className="no c-gray">
          {value !== 0 ? (
            <>
              {value.toFixed(2)}
              <span className="lw-gray"> KgCO2eq</span>
            </>
          ) : (
            <span className="lw-gray">{value}</span>
          )}
        </p>
        <p className="separate">|</p>
      </div>
    </td>
    <td className="  border-b " style={{ padding: "4px" }}>
      <div className=" flex justify-between">
        {totalSource !== 0 ? (
          <span>
            {((value * 100) / totalSource).toFixed(2)}
            <span className="lw-gray no"> %</span>
          </span>
        ) : (
          <span className="lw-gray no"> N/A</span>
        )}
        <p className="separate">|</p>
      </div>
    </td>
    <td className="  border-b " style={{ padding: "4px" }}>
      <div className=" flex justify-between">
        <p className={`no ${status === "normal" ? "c-blue" : ""}`}>{status}</p>
        <p className="separate">|</p>
      </div>
    </td>
  </tr>
);

const headers = ["Source", "Emissions", "Share", "Status"];

const TableHeader = ({ label }) => (
  <th className="  border-b " style={{ padding: "4px" }}>
    <div className=" flex justify-between">
      <p className="header-table">{label}</p>
      <p className="separate">|</p>
    </div>
  </th>
);

const ProgressBar = ({ color, emissionType, totalSource }) => (
  <div
    style={{
      backgroundColor: color,
      borderRadius: "2px",
      height: "4px",
      width: `${
        totalSource !== 0
          ? ((emissionType * 100) / totalSource).toFixed(2) + "%"
          : "25%"
      }`,
    }}
  ></div>
);

export default Highlights;
