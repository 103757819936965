import React from "react";

const ContactUs = ({ handleModal }) => {
  return (
    <div className="flex items-center justify-center flex-col md:px-32 md:py-16">
      <img className="" src="/header.svg" alt="Bottom Image" />
      <p className="mt-3 standard c-gray">Ver. 1.0.0</p>
      <p className="mt-8 standard c-gray">Contect us</p>
      <p className="mt-3 standard c-gray">+82 719 0920</p>
      <p className="mt-3 standard c-gray">jazzson@sesu.ai</p>
      <p className="mt-3 standard c-gray">sesu.ai</p>
      <button className=" mt-12 w-28 sub-no " onClick={handleModal}>close</button>
    </div>
  );
};

export default ContactUs;
