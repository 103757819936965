import React, { useState } from "react";

const MenuTree = () => {
  const [openMenu, setOpenMenu] = useState(null);

  const toggleMenu = (menuIndex) => {
    setOpenMenu((prevMenu) => (prevMenu === menuIndex ? null : menuIndex));
  };

  const isMenuOpen = (menuIndex) => openMenu === menuIndex;

  // Define upper-level and sub-level menus with URLs
  const menus = [
    {
      name: 'Flaring',
      subMenus: [
        { name: 'Activity Data', url: '/FFADE' },
        { name: 'Emission Factors', url: '/FFEFDE' },
      ],
    },
    {
      name: 'Venting',
      subMenus: [
        { name: 'Activity Data', url: '/Venting' },
      ],
    },
    {
      name: 'Fugitives',
      subMenus: [
        { name: 'Compressor Fugitives', url: '/FSGCFDE' },
        { name: 'Stablizer Fugitives', url: '/SFETF' },
        { name: 'Tank Fugitives', url: '/Tankf' },
      ],
    },
    {
      name: 'Combustion',
      subMenus: [
        { name: 'Separator Fuel Usage', url: '/SeparatorFConsumption' },
        { name: 'Heater Fuel Usage', url: '/FSEDE' },
        { name: 'Heater Emission Factors', url: '/FSSHEFDE' },
        { name: 'Compressor Fuel Usage ', url: '/FSGCEUDE' },
        { name: 'Compressor Emission Factors', url: '/FSGCEFDE' },
        
      ],
    },
  ];

  return (
    <div className="flex flex-col ">
      {/* Upper-level menus */}
      {menus.map((menu, menuIndex) => (
        <div key={menuIndex} className="relative">
          {/* Upper-level menu item */}
          <button
            style={{height:"36px"}}
            className="flex items-center menus space-x-2 c-gray  focus:outline-none transition-transform duration-300 ease-in-out"
            onClick={() => toggleMenu(menuIndex)}
          >
            {isMenuOpen(menuIndex) ? (
              <img
                className="mr-2 transform rotate-0 transition-transform duration-300 ease-in-out"
                src="/menu.svg"
                alt="Bottom Image"
              />
            ) : (
              <img
                className="mr-2 transform rotate-0 transition-transform duration-300 ease-in-out"
                src="/downmenu.svg"
                alt="Bottom Image"
              />
            )}
            {menu.name}
          </button>

          {isMenuOpen(menuIndex) && (
            <div className="pl-8  transition-all duration-300 ease-in-out opacity-100">
              {menu.subMenus.map((subMenu, subMenuIndex) => (
                <div key={subMenuIndex} className="transition-opacity duration-300 ease-in-out">
                  {/* Submenu item */}
                  <a
                    href={subMenu.url}
                    className="bold_special c-gray flex tags hover:text-blue-800 focus:outline-none"
                  >
                    {subMenu.name}
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MenuTree;
