import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout } from "../actions/userAction";
const Log_out = ({ handleModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogOut = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    navigate("/login");
  };
  return (
    <div className="flex items-center justify-center flex-col ">
      <img className="md:w-14 md:h-14 mb-5 " src="/logout.svg" alt="Bottom Image"  />
      <p className="standard c-gray text-base md:mb-16 mb-5">Are you sure you want to log out?</p>
      <div className="flex flex-wrap gap-6 items-center justify-center">
        <button className="w-28 sub-no " onClick={handleModal}>
          No
        </button>
        <button className="sub-btn w-28" onClick={handleLogOut}>
          Log out
        </button>
      </div>
    </div>
  );
};

export default Log_out;
