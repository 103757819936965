import {
  SET_SCREEEN_SIZE,
  SET_CURRENT_COLOR,
  SET_CURRENT_MODE,
  SET_THEMESETTINGS,
  SETACTIVEMENU,
} from "../constants/appConstants";

export const appReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SCREEEN_SIZE:
      return {
        ...state,
        screenSize: action.payload,
      };

    case SET_CURRENT_COLOR:
      localStorage.setItem("colorMode", action.payload);
      return {
        ...state,
        currentColor: action.payload,
      };
    case SET_CURRENT_MODE:
      localStorage.setItem("themeMode", action.payload);
      return {
        ...state,
        currentMode: action.payload,
      };
    case SET_THEMESETTINGS:
      return {
        ...state,
        themeSettings: action.payload,
      };
    case SETACTIVEMENU:
      return {
        ...state,
        activeMenu: action.payload,
      };
    default:
      return state;
  }
};
