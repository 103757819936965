
import React from "react";
import { FGDDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_glycol_dehydration_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout formName={"form_glycol_dehydration_data_entry"} title={"Glycol Dehydration"}   dataSet={FGDDE} />
    </PrimaryLayout>
  );
};
export default Form_glycol_dehydration_data_entry;



