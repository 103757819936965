import React from "react";
import { WTFC } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const WaterTankFlashComposition = () => {
  return (
    <PrimaryLayout>
      <CommonLayout formName={"waterTankFlashComposition"} title={"Water Tank Flash Composition"} dataSet={WTFC} />
    </PrimaryLayout>
  );
};
export default WaterTankFlashComposition;
