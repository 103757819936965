import React from 'react'
import ContactUs from './Contact';
import Log_out from './Logout';

const Modal = ({ heading, handleModal }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 100000000000,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: "rgba(0, 0, 0, 0.5)",
        }}
        onClick={() => {
          handleModal();
        }}
      />
      <div
        style={{
          background: "white",
          padding: "3%",
          zIndex: 100000000000,
          color: "#009cea",
        }} 
      >

        <div     style={{ display:"flex", alignItems:"center", flexDirection:"column"}}>
         {heading=="contact" && <ContactUs handleModal={handleModal}/>} 
         {heading=="logout" && <Log_out handleModal={handleModal}/>} 
          <div  >
          </div>
        </div>
      </div>
    </div>
  );
};


export default Modal
