import React, { useEffect, useRef, useState } from "react";

import { AiOutlineDoubleLeft } from "react-icons/ai";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { BiMemoryCard } from "react-icons/bi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { useSelector, useDispatch } from "react-redux";
import { SETACTIVEMENU, SET_SCREEEN_SIZE } from "../../constants/appConstants";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/userAction";
import { FaUpload } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import axios from "axios";
const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentColor, activeMenu, screenSize } = useSelector(
    (state) => state.app
  );
  const handleLogOut = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    navigate("/login");
  };
  useEffect(() => {
    const handleResize = () =>
      dispatch({
        type: SET_SCREEEN_SIZE,
        payload: window.innerWidth,
      });

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      dispatch({
        type: SETACTIVEMENU,
        payload: false,
      });
    } else {
      dispatch({
        type: SETACTIVEMENU,
        payload: true,
      });
    }
  }, [screenSize]);

  const handleActiveMenu = () =>
    dispatch({
      type: SETACTIVEMENU,
      payload: !activeMenu,
    });

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

   const handleFileUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        await axios.post("fetchData/data", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        alert("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      alert("Please select a file to upload.");
    }
  };
  useEffect(() => {
    // Automatically trigger the file upload when a file is added
    if (file) {
      handleFileUpload();
    }
  }, [file]);

  const handleFileDownload = async (type) => {
    try {
      const response = await axios.get(`/download/${type}`, {
        responseType: "blob", // Important for binary data like files
      });

      // Create a Blob object from the binary data
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger a click event to download the file
      const link = document.createElement("a");
      link.href = url;
      link.download = `data.${type}`;
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the temporary URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const fileInputRef = useRef(null);


  const [downloaded, setDownloaded] = useState(false);

  const handleDownload = async (type) => {

    // await axios.get(`fetchData/${type}`)
    // .then(response => {
    //   setMessage(response.data.message);
    //   console.log(response)
    //   console.log(response.data.message)

    // })
    // .catch(error => {
    //   console.error('Error fetching data:', error);
    // });


    
    try {
      const response = await axios.get(`fetchData/${type}`, {
        responseType: 'blob', // Set the response type to 'blob'
      });
  
      // Create a blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
      // Create a link element and trigger a download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'ExportedDatabase.xlsx';
      link.click();
  
      console.log('File downloaded successfully');
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };


  return (
    <div
      className=" fixed top-0 left-0 right-0  flex justify-end dark:bg-secondary-dark-bg  bg-white items-center w-full "
      style={{
        height: "64px",
        borderBottom: "1px solid #DDDDDD",
        zIndex: "100",
      }}
    >
      <div className=" bg-blue-800 w-full items-center ml-4 md:ml-0 flex md:justify-center  ">
        <img
          className="absolute"
          style={{ height: "30px", marginLeft: "" }}
          src="/headersmall.svg"
          alt="Bottom Image"
        />
      </div>
     
      <div className="flex fixed">
        <input
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          style={{ display: "none" }}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <button
          className={`hover:text-green-900  font-bold py-2 px-4`}
          onClick={() => fileInputRef.current.click()}
          style={{ color: "#999999" }}
        >
          <span className="flex gap-1 no">
            Upload
            <img className="" src="/upload.svg" />
          </span>
        </button>
        <button
          className={` hover:text-green-900  font-bold py-2 px-4`}
          onClick={() => handleDownload("xlsx")}
          style={{ color: "#999999" }}
        >
          <span className="flex gap-1 no">
            Download
            <img className="" src="/download.svg" />
          </span>
        </button>
  
      </div>
    </div>
  );
};

export default Navbar;
