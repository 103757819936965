import React from "react";
import { FSEDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_stablizer_emission_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout
        formName={"form_stablizer_emission_data_entry"} title={"Stablizer Emission"}
        dataSet={FSEDE} 
      />
    </PrimaryLayout>
  );
};
export default Form_stablizer_emission_data_entry;
