import React from "react";
import { FSVCDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_storage_vapor_composition_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout
        formName={"form_storage_vapor_composition_data_entry"} title={"Storage Vapor Composition"}
        dataSet={FSVCDE}
      />
    </PrimaryLayout>
  );
};
export default Form_storage_vapor_composition_data_entry;
