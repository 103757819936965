

import React from "react";
import { FFGMFDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_flaring_ghg_mass_fraction_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout formName={"form_flaring_ghg_mass_fraction_data_entry"} title={"Flaring GHG Mass Fraction"} dataSet={FFGMFDE} />
    </PrimaryLayout>
  );
};
export default Form_flaring_ghg_mass_fraction_data_entry;



