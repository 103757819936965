import React from "react";
import { FSGCEUDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_sales_gas_compressor_energy_use_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout
        formName={"form_sales_gas_compressor_energy_use_data_entry"}title={"Sales Gas Compressor Energy Use"}
        dataSet={FSGCEUDE}
      />
    </PrimaryLayout>
  );
};
export default Form_sales_gas_compressor_energy_use_data_entry;
