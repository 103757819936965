import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERRORS,
} from "../constants/userConstants";
import axios from "axios";

// Login
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.post(
      `/api/v1/login`,
      { email, password },
      config
    );
    localStorage.setItem('token', data.token);
    dispatch({ type: LOGIN_SUCCESS, payload: data });
   
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
  }
};

// Register
export const register = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });
    const { data } = await axios.post(`/api/v1/register`, userData);
    localStorage.setItem('token', data.token);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  dispatch({ type: LOAD_USER_REQUEST });
  const token = localStorage.getItem("token");
  if (!token) {
    dispatch({ type: LOAD_USER_FAIL, payload: "user not found" });
  }
  else{
    const { data } = await axios.get(`/api/v1/me`, {
      headers: {
        Authorization: `Bearer ${token}`, // send token in the Authorization header
      },
    });
    dispatch({ type: LOAD_USER_SUCCESS, payload: data });
  }
  // const { data } = await axios.get(`/api/v1/me`, {
  //   headers: {
  //     Authorization: `Bearer ${token}`, // send token in the Authorization header
  //   },
  // });
  // dispatch({ type: LOAD_USER_SUCCESS, payload: data });
};

// Logout User
export const logout = () => async (dispatch) => {

  dispatch({ type: LOGOUT_SUCCESS });

};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
