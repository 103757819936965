import React from "react";
import "./NotFound.css";
import { Link } from "react-router-dom";
import { BiError } from 'react-icons/bi';

const NotFound = () => {
  return (
    <div className="PageNotFound">
      <BiError  />

      <h1>Page Not Found </h1>
      <Link to="/">Home</Link>
    </div>
  );
};

export default NotFound;
