import { BsCalendarDateFill, BsCurrencyDollar, BsShield } from "react-icons/bs";

import { BiHighlight } from "react-icons/bi";
import { FcApproval } from "react-icons/fc";
import { FiCreditCard } from "react-icons/fi";
import { MdOutlinePreview } from "react-icons/md";
import { SiCodenewbie } from "react-icons/si";

export const FFADE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "Date_of_flaring ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Date_of_flaring",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Flow Rate",
    desc: "(m^3)",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Mass_flow_rate",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "FEF_ID",
    desc: "(1)",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "FEF_ID",
    type: "select",
  },
  // {
  //   icon: <SiCodenewbie />,
  //   amount: "22-08-18",
  //   title: "GMFE_ID",
  //   desc: "(1) ",
  //   iconColor: "#03C9D7",
  //   iconBg: "#E5FAFB",
  //   pcColor: "green-600",
  //   name: "GMFE_ID",
  //   type: "number",
  // },

  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Conversion_efficiency",
    desc: "[0,1] ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Conversion_efficiency",
    type: "number",
  },
];


/*
req.body.EF_NOx,
req.body.EF_N2O,
req.body.EF_PM1,
req.body.EF_PM2,
req.body.EF_PM3,

*/

export const FFEFDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "CO",
    desc: "EF_CO ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_CO",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "CO_2",
    desc: "EF_CO_2 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_CO_2",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "NOx",
    desc: "EF_NOx ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_NOx",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "N2O",
    desc: "EF_N2O ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_N2O",
    type: "number",
  },

  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "PM1",
    desc: "EF_PM1 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_PM1",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "PM2",
    desc: "EF_PM2 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_PM2",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "PM3",
    desc: "EF_PM3 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_PM3",
    type: "number",
  },
];
export const FFGMFDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "MF_C",
    desc: "MF_C ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_C",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "MF_S",
    desc: "MF_S ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_S",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "MF_CH4",
    desc: "MF_CH4 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_CH4",
    type: "number",
  },
 
];

export const FGDDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "emission_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "emission_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Gas_volume",
    desc: "Processed_gas_volume ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Processed_gas_volume",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Emission_factor",
    desc: "glycol_methane_EF ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "glycol_methane_emission_factor",
    type: "number",
  },
 
];

export const FSGCEFDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "EF_CO",
    desc: "EF_CO ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_CO",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_PM",
    desc: "EF_PM ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_PM",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_HCNM",
    desc: "EF_HCNM ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_HCNM",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_N2O",
    desc: "EF_N2O ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_N2O",
    type: "number",
  },

  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_CH4",
    desc: "EF_CH4 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_CH4",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_HCT",
    desc: "EF_HCT ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_HCT",
    type: "number",
  },
];


export const REPORT = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "date_of_use ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "date_of_use",
    type: "date",
  },
];



export const FSGCEUDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "date_of_use ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "date_of_use",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Gas_consumed",
    desc: "Natural_gas_consumed ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Natural_gas_consumed",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "CEF_ID",
    desc: "CEF_ID ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "CEF_ID",
    type: "number",
  },
 
];
export const FSGCFDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "procurment_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "procurment_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Loss_rate",
    desc: "Loss_rate ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Loss_rate",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Mass_flow_rate",
    desc: "Mass_flow_rate ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Mass_flow_rate",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "C_GHG_C_ID",
    desc: "C_GHG_C_ID ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "C_GHG_C_ID",
    type: "number",
  },

];
export const FSGCGCDA = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "procurment_date",
    desc: "procurment_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "procurment_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "M_CO",
    desc: "M_CO ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "M_CO",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "M_CO_2",
    desc: "M_CO_2 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "M_CO_2",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "M_HCNM",
    desc: "M_HCNM ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "M_HCNM",
    type: "number",
  },

  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "M_CH4",
    desc: "M_CH4 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "M_CH4",
    type: "number",
  },
];
export const FSHPGMFDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "MF_CO",
    desc: "MF_CO ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_CO",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "MF_MP",
    desc: "MF_MP ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_MP",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "MF_HCNM",
    desc: "MF_HCNM ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_HCNM",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "MF_N2O",
    desc: "MF_N2O ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_N2O",
    type: "number",
  },

  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "MF_CH4",
    desc: "MF_CH4 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_CH4",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "MF_HCT",
    desc: "MF_HCT ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_HCT",
    type: "number",
  },
];


export const FSEDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "Combustion_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Combustion_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Gas_consumed",
    desc: "Natural_gas_consumed ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Natural_gas_consumed",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "HEF_ID",
    desc: "HEF_ID ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "HEF_ID",
    type: "number",
  },
];

export const FSSHEFDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "EF_CO",
    desc: "EF_CO ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_CO",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_MP",
    desc: "EF_MP ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_MP",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_HCNM",
    desc: "EF_HCNM ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_HCNM",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_N2O",
    desc: "EF_N2O ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_N2O",
    type: "number",
  },

  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_CH4",
    desc: "EF_CH4 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_CH4",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "EF_HCT",
    desc: "EF_HCT ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "EF_HCT",
    type: "number",
  },
];
export const FSVCDE = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "procurment_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "procurment_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "M_CO",
    desc: "M_CO ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "M_CO",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "M_CO_2",
    desc: "M_CO_2 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "M_CO_2",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "M_HCNM",
    desc: "M_HCNM ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "M_HCNM",
    type: "number",
  },

  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "M_CH4",
    desc: "M_CH4 ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "M_CH4",
    type: "number",
  },
];




export const Venting = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "Venting_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Venting_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Vented_gas_volume",
    desc: "Vented_gas_volume ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Vented_gas_volume",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "vented_gas_density",
    desc: "vented_gas_density ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "vented_gas_density",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Methane_percentage",
    desc: "Methane_percentage ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Methane_percentage",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Methane_density",
    desc: "Methane_density ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Methane_density",
    type: "number",
  },
];


export const SFETF = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "procurement_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "procurement_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Loss_rate",
    desc: "Loss_rate ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Loss_rate",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Gas_volume",
    desc: "Gas_volume ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Gas_volume",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "MF_ID",
    desc: "MF_ID ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "MF_ID",
    type: "number",
  },
];



export const Tankf = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "procurment_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "procurment_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Flash_factor",
    desc: "Flash_factor ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Flash_factor",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Mass_flow_rate",
    desc: "Mass_flow_rate ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Mass_flow_rate",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "SVC_ID",
    desc: "SVC_ID ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "SVC_ID",
    type: "number",
  },
];









export const SFC = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "Consumption_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Consumption_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "fuel_type",
    desc: "fuel_type ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "fuel_type",
    type: "fixed",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "FAC",
    desc: "Amount_consumed ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Amount_consumed",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "FEFS",
    desc: "Emission_factor ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Emission_factor",
    type: "number",
  },
];

export const SIO = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "Registry_date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Registry_date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "API",
    desc: "API_gravity ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "API",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "GOR",
    desc: "GOR ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "GOR",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Separator_temp",
    desc: "Separator_temp ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Separator_temp",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Separator_pressure",
    desc: "Separator_pressure ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Separator_pressure",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "PGV",
    desc: "produced_gas_volume ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "produced_gas_volume",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "Methane_fraction",
    desc: "Methane_fraction ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Methane_fraction",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "NMHC_fraction",
    desc: "NMHC_fraction ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "NMHC_fraction",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "WTV",
    desc: "Water_Tank_volume ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Water_Tank_volume",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "POV",
    desc: "produced_oil_volume ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "produced_oil_volume",
    type: "number",
  },
];

export const WTFC = [
  {
    icon: <BsCalendarDateFill />,
    amount: "22-08-18",
    title: "Date",
    desc: "Date ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "Date",
    type: "date",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "flash_gas_volume",
    desc: "flash_gas_volume ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "flash_gas_volume",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "methan_fraction",
    desc: "methan_fraction ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "methan_fraction",
    type: "number",
  },
  {
    icon: <SiCodenewbie />,
    amount: "22-08-18",
    title: "NMHC_fraction",
    desc: "NMHC_fraction ",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    pcColor: "green-600",
    name: "NMHC_fraction",
    type: "number",
  },
];





export const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: "My Profile",
    desc: "Account Settings",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
  },
  {
    icon: <BsShield />,
    title: "My Inbox",
    desc: "Messages & Emails",
    iconColor: "rgb(0, 194, 146)",
    iconBg: "rgb(235, 250, 242)",
  },
  {
    icon: <FiCreditCard />,
    title: "My Tasks",
    desc: "To-do and Daily Tasks",
    iconColor: "rgb(255, 244, 229)",
    iconBg: "rgb(254, 201, 15)",
  },
];

export const themeColors = [
  {
    name: "blue-theme",
    color: "#1A97F5",
  },
  {
    name: "green-theme",
    color: "#03C9D7",
  },
  {
    name: "purple-theme",
    color: "#7352FF",
  },
  {
    name: "red-theme",
    color: "#FF5C8E",
  },
  {
    name: "indigo-theme",
    color: "#1E4DB7",
  },
  {
    color: "#FB9678",
    name: "orange-theme",
  },
];
export const emissions = [
 

  {
    title: "Emission Dashboard",
    links: [
      {
        name: "Source Emission",
        icon: <BiHighlight />,
      },
      {
        name: "GHG Species",
        icon: <BiHighlight />,
      },
      {
        name: "Stages",
        icon: <BiHighlight />,
      },
    ],
  },]
export const links = [
 

  {
    title: "Sales Gas Compressor",
    links: [
      {
        name: "FSGCEFDE",
        icon: <BiHighlight />,
      },
      {
        name: "FSGCGCDA",
        icon: <BiHighlight />,
      },
      {
        name: "FSGCEUDE",
        icon: <BiHighlight />,
      },
      {
        name: "FSGCFDE",
        icon: <BiHighlight />,
      },
    ],
  },
  // {
  //   title: "Oil Stablization",
  //   links: [
  //     {
  //       name: "SFETF",
  //       icon: <BiHighlight />,
  //     },
  //     {
  //       name: "FSHPGMFDE",
  //       icon: <BiHighlight />,
  //     },
  //     {
  //       name: "FSSHEFDE",
  //       icon: <BiHighlight />,
  //     },
  //     {
  //       name: "FSEDE",
  //       icon: <BiHighlight />,
  //     },
  //   ],
  // },
  // {
  //   title: "Flash Gas Compressor and Flares",
  //   links: [
  //     {
  //       name: "FFEFDE",
  //       icon: <BiHighlight />,
  //     },
  //     {
  //       name: "FFADE",
  //       icon: <BiHighlight />,
  //     },
  //     {
  //       name: "FFGMFDE",
  //       icon: <BiHighlight />,
  //     },
  //   ],
  // },
  // {
  //   title: "Oil Storage and Loading",
  //   links: [
  //     {
  //       name: "Tankf",
  //       icon: <BiHighlight />,
  //     },
  //     {
  //       name: "FSVCDE",
  //       icon: <BiHighlight />,
  //     },
  //     {
  //       name: "Venting",
  //       icon: <BiHighlight />,
  //     },
  //   ],
  // },
  // {
  //   title: "Separator",
  //   links: [
  //     {
  //       name: "SeparatorFConsumption",
  //       icon: <BiHighlight />,
  //     },
  //     {
  //       name: "SeparatorIO",
  //       icon: <BiHighlight />,
  //     },
  //   ],
  // },
  // {
  //   title: "Water Disposal",
  //   links: [
  //     {
  //       name: "WaterTFComposition",
  //       icon: <BiHighlight />,
  //     },
  //   ],
  // },
  // {
  //   title: "Glycol Dehydration",
  //   links: [
  //     {
  //       name: "FGDDE",
  //       icon: <BiHighlight />,
  //     },
  //   ],
  // },
 
];

export const reminders = [
  {
    icon: <SiCodenewbie />,
    amount: "354",
    percentage: "-4%",
    title: "Restictions",
    iconColor: "purple",
    iconBg: "#E5FAFB",
    pcColor: "red-600",
    animate: "red",
  },
  {
    icon: <MdOutlinePreview />,
    amount: "96",
    percentage: "+23%",
    title: "Global Risks",
    iconColor: "rgb(255, 244, 229)",
    iconBg: "rgb(254, 201, 15)",
    pcColor: "green-600",
    animate: "red",
  },
  {
    icon: <FcApproval />,
    amount: "423,309",
    percentage: "+38%",
    title: "Fabrications",
    iconColor: "green",
    iconBg: "rgb(255, 244, 229)",

    pcColor: "green-600",
    animate: "green",
  },
];


