import React, { useEffect, useState } from "react";
import Footer from "./Footer/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import Navbar from "./header/Navbar";
import ThemeSettings from "./ThemeSettings";
import Loader from "./Loader/Loader";
import { SETACTIVEMENU, SET_THEMESETTINGS } from "../constants/appConstants";
import MetaData from "./MetaData";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { logout } from "../actions/userAction";
import Modal from "../modal/Modal";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);
const PrimaryLayout = (props) => {
  const location = useLocation();
  const [preview, setPreview] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [popupType, setPopupType] = useState("");
  const handleModal = (header) => {
    setOpenModal(!openModal);
    setPopupType(header);
  };

  useEffect(() => {
    setPreview(location.pathname);
  }, [location]);

  const { isAuthenticated, loading, user } = useSelector((state) => state.user);
  const { currentMode, activeMenu, currentColor, themeSettings, screenSize } =
    useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    !isAuthenticated && navigate("/login", { state: { from: location } });
  }, [isAuthenticated]);

  if (loading) return <Loader />;
  const handleThemeSetting = () => {
    dispatch({
      type: SET_THEMESETTINGS,
      payload: true,
    });
  };

  const handleActiveMenu = () =>
    dispatch({
      type: SETACTIVEMENU,
      payload: !activeMenu,
    });
  const handleLogOut = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    navigate("/login");
  };
  return (
    <>
      <MetaData title={"GHG"} />
      <div
        style={{ overflowX: "hidden" }}
        className={currentMode === "Dark" ? "w-full dark   " : ""}
      >
        <div className={"dark:bg-main-dark-bg  navbar "}>
          <Navbar />
        </div>
        <div className="w-full flex relative dark:bg-main-dark-bg">
          {/* <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                onClick={handleThemeSetting}
                style={{ background: currentColor, borderRadius: "50%" }}
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div> */}
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar preview={preview} />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar preview={preview} />
            </div>
          )}

          <div
            className={
              activeMenu
                ? ` ${screenSize >= 900 ? "ml-72" : ""} w-full`
                : "bg-main-bg dark:bg-main-dark-bg  w-full  flex-2 "
            }
          >
            <div
              className="w-72  flex items-center justify-evenly fixed bottom-0 left-0 "
              style={{ height: "52px", borderTop: "1px solid #DDDDDD", borderRight:"1px solid #DDDDDD", backgroundColor:"white" }}
            >
              <button disabled onClick={handleThemeSetting}>
                <img className="" src="/setting.svg" alt="Bottom Image" />
              </button>
              <button onClick={() => handleModal("contact")}>
                <img className="" src="/contact.svg" alt="Bottom Image" />
              </button>
              <button onClick={() => handleModal("logout")}>
                <img
                  className=""
                  src="/logout.svg"
                  alt="Bottom Image"
                  //handleLogOut
                />
              </button>
              <div className="h-full -ml-4 -mr-4" style={{ width:"1px", backgroundColor:"#DDDDDD"}} ></div>
              {activeMenu ? (
                <button  onClick={handleActiveMenu}>
                  <img className="" src="/switch.svg" alt="Bottom Image" />
                </button>
              ) : (
                <button onClick={handleActiveMenu}>
                  <img className="" src="/left.svg" alt="Bottom Image" />
                </button>
              )}

              {/* <button onClick={handleModal}>hu</button> */}

              {/* <NavButton
                title={null}
                customFunc={handleActiveMenu}
                color={currentColor}
                icon={
                  activeMenu ? (
                    <AiOutlineDoubleLeft />
                  ) : (
                    <AiOutlineDoubleRight />
                  )
                }
              />{" "} */}
            </div>
            <div>
              {themeSettings && <ThemeSettings />}

              {props.children}
            </div>
          </div>
        </div>
      </div>
      {openModal && <Modal handleModal={handleModal} heading={popupType} />}
    </>
  );
};

export default PrimaryLayout;
