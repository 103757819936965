import React, { useEffect, useRef, useState } from "react";
import {
  createData,
  fetchData,
  fetchInitialData,
  fetchReport,
} from "../../utilis/api";
import { FSGCEFDE, REPORT } from "../../data/dummy-files";
import { useSelector, useDispatch } from "react-redux";
import Blink from "../layout/header/Blink";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import PrimaryLayout from "../layout/PrimaryLayout";
import DateRangePickr, { DataPerDay, DataPerRangeDay } from "./DateRangePickr";
import axios from "axios";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
);
export const options = (lastDays) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Last ${lastDays} days`,
      },
    },
  };
};

export const optionsPie = (datas) => {
  const data = datas[0].date;
  const formattedDate = moment(data).format("MMM DD YYYY");

  return {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: formattedDate,
      },
    },
  };
};

export const dataGHG = (datas) => {
  return {
    labels: datas.map((item) => moment(item.date).format("MMM DD, YYYY")),
    datasets: [
      {
        label: "CO2",
        data: datas.map((item) => item.CO2),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "N2O",
        data: datas.map((item) => item.N2O),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "CH4",
        data: datas.map((item) => item.CH4),
        borderColor: "rgba(54, 162, 35, 0.5)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "HCNM",
        data: datas.map((item) => item.HCNM),
        borderColor: "rgb(200, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

// export const dataPieGHG =  {
//   labels: ["CO2", "N2O", "CH4", "HCNM"],
//   datasets: [
//     {
//       label: "amt",
//       data: [12, 5, 2, 3],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.2)",
//         "rgba(54, 162, 235, 0.2)",
//         "rgba(255, 206, 86, 0.2)",
//         "rgba(75, 192, 192, 0.2)",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(75, 192, 192, 1)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };
export const dataPieGHG = (datas) => {
  const data = datas[0];
  return {
    labels: ["CO2", "N2O", "CH4", "HCNM"],
    datasets: [
      {
        label: "amt",
        data: [data.CO2, data.N2O, data.CH4, data.HCNM],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
};

export const data = (datas) => {
  return {
    labels: datas.map((item) => moment(item.date).format("MMM DD, YYYY")),
    datasets: [
      {
        label: "Flaring",
        data: datas.map((item) => item.flaring),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Combustion",
        data: datas.map((item) => item.combustion),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Venting",
        data: datas.map((item) => item.venting),
        borderColor: "rgba(54, 162, 35, 0.5)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Fugitives",
        data: datas.map((item) => item.fugitives),
        borderColor: "rgb(200, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
};

export const dataPie = (datas) => {
  const data = datas[0];
  return {
    labels: ["Flaring", "Combustion", "Venting", "Fugitives"],
    datasets: [
      {
        label: "amt",
        data: [data.flaring, data.combustion, data.venting, data.fugitives],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
};

const Preview = (props) => {
  const { currentColor } = useSelector((state) => state.app);
  const [ghgDay, setGhgDay] = useState();
  const [ghgRange, setGhgRange] = useState();
  const [source, setSource] = useState();
  const [sourceRange, setSourceRange] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingghgDay, setLoadingghgDay] = useState(false);
  const [loadingghgRange, setLoadingghgRange] = useState(false);
  const [loadingsource, setLoadingsource] = useState(false);
  const [loadingsourceRange, setLoadingsourceRange] = useState(false);

  useEffect(() => {
    fetchInitialData()
      .then((responseData) => {
        setGhgDay(responseData[0]);
        setGhgRange(responseData[1]);
        setSource(responseData[2]);
        setSourceRange(responseData[3]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      });
  }, []);

  const fetchDataByDay = async (type, date) => {
    if (type === "ghg") {
      try {
        setLoadingghgDay(true);
        const response = await axios.get(
          `fetchData/data-by-date/${type}/${date}`
        );
        setGhgDay(response.data);
      } catch (error) {
        console.error("Error fetching data by date: ", error);
      } finally {
        setLoadingghgDay(false);
      }
    } else {
      try {
        setLoadingsource(true);
        const response = await axios.get(
          `fetchData/data-by-date/${type}/${date}`
        );
        setSource(response.data);
      } catch (error) {
        console.error("Error fetching data by date: ", error);
      } finally {
        setLoadingsource(false);
      }
    }
  };

  const fetchDataByRange = async (type, start, end) => {
    if (type === "ghg") {
      try {
        setLoadingghgRange(true);
        const response = await axios.get(
          `fetchData/data-by-date-range/${type}/${start}/${end}`
        );
        setGhgRange(response.data);
      } catch (error) {
        console.error("Error fetching data by date range: ", error);
      } finally {
        setLoadingghgRange(false);
      }
    } else {
      try {
        setLoadingsourceRange(true);
        const response = await axios.get(
          `fetchData/data-by-date-range/${type}/${start}/${end}`
        );
        setSourceRange(response.data);
      } catch (error) {
        console.error("Error fetching data by date range: ", error);
      } finally {
        setLoadingsourceRange(false);
      }
    }
  };
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        await axios.post('/upload/data', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        alert('File uploaded successfully');
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      alert('Please select a file to upload.');
    }
  };


  useEffect(() => {
    // Automatically trigger the file upload when a file is added
    if (file) {
      handleFileUpload();
    }
  }, [file]);

  const handleFileDownload = async (type) => {
    try {
      const response = await axios.get(`/download/${type}`, {
        responseType: 'blob', // Important for binary data like files
      });

      // Create a Blob object from the binary data
      const blob = new Blob([response.data], { type: 'application/octet-stream' });

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger a click event to download the file
      const link = document.createElement('a');
      link.href = url;
      link.download = `data.${type}`;
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the temporary URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  const fileInputRef = useRef(null);

  return (
    <PrimaryLayout>
      <div className="bg-green-800  flex center justify-center	items-center gap-12 mb-24">
      {/* <input
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => fileInputRef.current.click()}
      >
        Upload File
      </button>
      <button
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => handleFileDownload('xlsx')}
      >
        Download File
      </button> */}


       
      </div>
      <div className=" flex flex-col justify-items-center  ">
        <div className="m-2 md:m-10 md:-mt-12 p-2 md:p-24 bg-white rounded-3xl 	  ">
          {/* <Blink /> */}
          <div className="mt-12">
            <div className="">
              <div className="flex max-w-screen-2xl items-center justify-center gap-10 bg-gray-200 p-4 rounded-xl  ">
                <div className="w-1/2 bg-gray-200">
                  <h1 className="mb-6"> Emission by source</h1>
                  <DataPerRangeDay
                    type={"source"}
                    fetchData={fetchDataByRange}
                  />
                  {isLoading ? (
                    <p className="mt-12">Loading...</p>
                  ) : loadingsourceRange ? (
                    <p className="mt-12">Loading ...</p>
                  ) : sourceRange.length !== 0 ? (
                    <Line
                      options={options(sourceRange.length)}
                      data={data(sourceRange)}
                    />
                  ) : (
                    <div className="mt-12">No data to show for this range.</div>
                  )}
                </div>
                <div className="w-1/2    flex flex-col items-center justify-center ">
                  <div className="mt-6 ">
                    <DataPerDay type={"source"} fetchData={fetchDataByDay} />
                  </div>

                  {isLoading ? (
                    <p className="mt-12">Loading...</p>
                  ) : loadingsource ? (
                    <p className="mt-12">Loading ...</p>
                  ) : source[0] ? (
                    <div className="w-1/2 mt-6 ">
                      <Doughnut
                        options={optionsPie(source)}
                        data={dataPie(source)}
                      />
                    </div>
                  ) : (
                    <div className="mt-12">No data to show this day</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-12">
              <div className="flex max-w-screen-2xl items-center justify-center gap-10 bg-gray-200 p-4 rounded-xl  ">
                <div className="w-1/2 bg-gray-200">
                  <h1 className="mb-6"> Emission by GHG species</h1>
                  <DataPerRangeDay type={"ghg"} fetchData={fetchDataByRange} />

                  {isLoading ? (
                    <p className="mt-12">Loading...</p>
                  ) : loadingghgRange ? (
                    <p className="mt-12">Loading ...</p>
                  ) : ghgRange.length !== 0 ? (
                    <Line
                      options={options(ghgRange.length)}
                      data={dataGHG(ghgRange)}
                    />
                  ) : (
                    <div className="mt-12">No data to show for this range.</div>
                  )}
                </div>
                <div className="w-1/2    flex flex-col items-center justify-center ">
                  <div className="mt-10">
                    <DataPerDay type={"ghg"} fetchData={fetchDataByDay} />
                  </div>

                  {isLoading ? (
                    <p className="mt-12">Loading...</p>
                  ) : loadingghgDay ? (
                    <p className="mt-12">Loading ...</p>
                  ) : ghgDay[0] ? (
                    <div className="w-1/2 mt-6 ">
                      <Doughnut
                        options={optionsPie(ghgDay)}
                        data={dataPieGHG(ghgDay)}
                      />
                    </div>
                  ) : (
                    <div className="mt-12">No data to show this day</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  );
};
export default Preview;
