import React from "react";
import { SFETF } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Stabilizer_fugitive_emissions = () => {
  return (
    <PrimaryLayout>
      <CommonLayout
        formName={"stabilizer_fugitive_emissions"} title={"Stabilizer Fugitive Emissions"}
        dataSet={SFETF}
      />
    </PrimaryLayout>
  );
};
export default Stabilizer_fugitive_emissions;
