

import React from "react";
import { FSGCFDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_sales_gas_compressor_fugitives_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout
        formName={"form_sales_gas_compressor_fugitives_data_entry"} title={"Sales Gas Compressor Fugitives"}
        dataSet={FSGCFDE}
      />
    </PrimaryLayout>
  );
};
export default Form_sales_gas_compressor_fugitives_data_entry;


