import { Link, NavLink, Navigate } from "react-router-dom";

import { MdOutlineCancel } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import React from "react";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { emissions, links } from "../../data/dummy-files";
import { useSelector, useDispatch } from "react-redux";
import { SETACTIVEMENU } from "../constants/appConstants";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { AiOutlineDoubleRight } from "react-icons/ai";
import MenuTree from "../MenuTree";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaUserTie } from "react-icons/fa";


const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Sidebar = ({ preview }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentColor, activeMenu, screenSize } = useSelector(
    (state) => state.app
  );

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      dispatch({
        type: SETACTIVEMENU,
        payload: false,
      });
    }
  };
  const handleActiveMenu = () =>
    dispatch({
      type: SETACTIVEMENU,
      payload: !activeMenu,
    });

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div
      className={
        activeMenu
          ? "relative h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 "
          : ""
      }
      style={{
        borderRight: "1px solid #DDDDDD",
        marginTop: "64px",
        zIndex: 10,
      }}
    >
      {activeMenu && (
        <>
          <div
            className="flex  items-center justify-center  "
            style={{ height: "120px", borderBottom: "1px solid #DDDDDD" }}
          >
            {/* Flex Container for Image and Text */}

            <div className="flex  items-center gap-2   ">
            <FaUserTie size={48} />
              <div>
                <p className="standard c-gray ">Welcome to Save the Earth</p>
                <span className="standard_b c-gray">User ID: 20235381</span>
                <p className="special">Administrator</p>
              </div>
            </div>
            {/* <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() =>
                  dispatch({
                    type: SETACTIVEMENU,
                    payload: !activeMenu,
                  })
                }
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent> */}

            {/* User ID */}
          </div>
          <div className="flex justify-between items-center">
            {/* <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <MdPayments /> <span>ComNet</span>
            </Link> */}
          </div>
          {/* <div className="flex  items-center ml-6">
          <MenuTree/>
          </div> */}
          <div className="flex  items-center  ml-6 mr-6 my-6 gap-4">
            
          <NavLink
              to={`/preview`}
              className={({ isActive }) =>
                isActive
                  ? "flex blue-btn flex-1 items-center justify-center"
                  : "flex white-btn flex-1 items-center justify-center"
              }
            >
              Dashboard
            </NavLink>
            <button
              className={`${
                preview === "/preview"
                  ? "flex white-btn flex-1 items-center justify-center"
                  : "flex blue-btn flex-1 items-center justify-center"
              }`}
              onClick={() => navigate("/FFADE")}
            >
              Data Settings
            </button>
          
          </div>
          {preview == "/preview" ? (
            <></>
          ) : (
            <div className="flex  items-center ml-6">
              <MenuTree />
            </div>
          )}
          {/* <div className="bg-green-900">
            {preview === "/preview"
              ? emissions.map((item) => (
                  <div key={item.title}>
                    <p className="text-gray-400 dark:text-gray-400 ml-3  uppercase">
                      {item.title}
                    </p>
                    {item.links.map((link) => (
                      <NavLink
                        to={`/${link.name}`}
                        key={link.name}
                        onClick={handleCloseSideBar}
                        style={({ isActive }) => ({
                          backgroundColor: isActive ? currentColor : "",
                        })}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                      >
                        {link.icon}
                        <span className="capitalize ">{link.name}</span>
                      </NavLink>
                    ))}
                  </div>
                ))
              : links.map((item) => (
                  <div key={item.title}>
                    <p className="text-gray-400 dark:text-gray-400 ml-3  uppercase">
                      {item.title}
                    </p>
                    {item.links.map((link) => (
                      <NavLink
                        to={`/${link.name}`}
                        key={link.name}
                        onClick={handleCloseSideBar}
                        style={({ isActive }) => ({
                          backgroundColor: isActive ? currentColor : "",
                        })}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                      >
                        {link.icon}
                        <span className="capitalize ">{link.name}</span>
                      </NavLink>
                    ))}
                  </div>
                ))}
          </div> */}
        </>
      )}
    </div>
  );
};

export default Sidebar;
