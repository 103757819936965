import { sumReports } from "../../utilis/api";
import React, { useEffect, useState } from "react";
import DigitalRead from "../Digitalread/DigitalRead";

const SumUp = () => {
  const [sumReportAdd, setSumReportAdd] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    sumReports()
      .then((responseData) => {
        if (isMounted) {
          setSumReportAdd(responseData);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      // Set isMounted to false when the component is unmounted
      isMounted = false;
    };
  }, []);

  return (
    <div
      className=""
      style={{
        backgroundColor: "#EEF0F2",
        padding: "20px 52px",
        marginTop: "64px",
      }}
    >
      <h1 className="mb-2 header-boxs  header-large c-gray ">
        Summary Information
      </h1>

      {isLoading ? (
        <p>Loading</p>
      ) : (
        <div className="flex flex-wrap  items-center gap-4   ">
          <div
            className="flex-grow p-4  "
            style={{
              padding: "12px 20px",
              backgroundColor: "#FFFFFF",
              border: "1px solid #DDDDDD",
              borderRadius: "8px",
              minWidth: "246px",
            }}
          >
            <div className="flex md:items-center justify-between gap-4 ">
              <p className="header-boxs c-gray">Total</p>
            </div>
            <p className="bold_special lw-gray">Emissions</p>
            <DigitalRead
              number={
                sumReportAdd
                  ? sumReportAdd.combustion +
                    sumReportAdd.flaring +
                    sumReportAdd.fugitives +
                    sumReportAdd.venting
                  : "0"
              }
            />
          </div>
          <div
            className="flex-grow p-4  "
            style={{
              padding: "12px 20px",
              backgroundColor: "#FFFFFF",
              border: "1px solid #DDDDDD",
              borderRadius: "8px",
              minWidth: "246px",
            }}
          >
            <div className="flex md:items-center justify-between gap-4 ">
              <p className="header-boxs c-gray">Combustion</p>
              <img className="" src="/combustion.svg" alt="emm" />
            </div>
            <p className="bold_special lw-gray">Emissions</p>

            <DigitalRead
              number={sumReportAdd ? sumReportAdd.combustion : "0"}
            />
          </div>
          <div
            className="flex-grow p-4 "
            style={{
              padding: "12px 20px",
              backgroundColor: "#FFFFFF",
              border: "1px solid #DDDDDD",
              borderRadius: "8px",
              minWidth: "246px",
            }}
          >
            <div className="flex md:items-center justify-between gap-4 ">
              <p className="header-boxs c-gray">Flaring</p>
              <img className="" src="/flaring.svg" alt="emm" />
            </div>
            <p className="bold_special lw-gray">Emissions</p>
            <DigitalRead number={sumReportAdd ? sumReportAdd.flaring : "0"} />
          </div>
          <div
            className="flex-grow p-4 "
            style={{
              padding: "12px 20px",
              backgroundColor: "#FFFFFF",
              border: "1px solid #DDDDDD",
              borderRadius: "8px",
              minWidth: "246px",
            }}
          >
            <div className="flex md:items-center justify-between gap-4 ">
              <p className="header-boxs c-gray">Fugitive</p>
              <img className="" src="/fugitive.svg" alt="emm" />
            </div>
            <p className="bold_special lw-gray">Emissions</p>
            <DigitalRead number={sumReportAdd ? sumReportAdd.fugitives : "0"} />
          </div>
          <div
            className="flex-grow p-4 "
            style={{
              padding: "12px 20px",
              backgroundColor: "#FFFFFF",
              border: "1px solid #DDDDDD",
              borderRadius: "8px",
              minWidth: "246px",
            }}
          >
            <div className="flex md:items-center justify-between gap-4 ">
              <p className="header-boxs c-gray">Venting</p>
              <img className="" src="/venting.svg" alt="emm" />
            </div>
            <p className="bold_special lw-gray">Emissions</p>
            <DigitalRead number={sumReportAdd ? sumReportAdd.venting : "0"} />
          </div>

          {/* {reminders.map((item) => (
        <div
          key={item.title}
          className="bg-white flex justify-center items-center dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl "
        >
          <button
            type="button"
            style={{
              color: item.iconColor,
              backgroundColor: item.iconBg,
            }}
            className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
          >
            {item.icon}
          </button>
          <p className="mt-3 mb-2 ml-2">
            <span className="text-lg font-semibold">{item.amount}</span>
            <span className={`text-sm text -${item.pcColor} ml-2`}>
              {item.title}
            </span>
          </p>
        </div>
      ))} */}
        </div>
      )}
    </div>
  );
};

export default SumUp;
