import React from "react";
import { FFEFDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_flaring_emission_factors_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout formName={"form_flaring_emission_factors_data_entry"} title={"Flaring Emission Factors"} dataSet={FFEFDE} />
    </PrimaryLayout>
  );
};
export default Form_flaring_emission_factors_data_entry;
