import React from "react";
import { FSGCEFDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_sales_gas_compressor_emission_factors_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout
        formName={"form_sales_gas_compressor_emission_factors_data_entry"}
        title={"Sales Gas Compressor Emission Factors"}
        dataSet={FSGCEFDE}
      />
    </PrimaryLayout>
  );
};
export default Form_sales_gas_compressor_emission_factors_data_entry;
