import React from "react";
import { Venting } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_venting_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout formName={"form_venting_data_entry"} title={"Venting"} dataSet={Venting} /> 
    </PrimaryLayout>
  );
};
export default Form_venting_data_entry;
