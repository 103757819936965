import "./App.css";
import { BrowserRouter, Route,Navigate, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Form_flaring_activity_data_entry from "./components/AddData/form_flaring_activity_data_entry";
import Form_flaring_emission_factors_data_entry from "./components/AddData/form_flaring_emission_factors_data_entry";
import Form_flaring_ghg_mass_fraction_data_entry from "./components/AddData/form_flaring_ghg_mass_fraction_data_entry";
import Form_glycol_dehydration_data_entry from "./components/AddData/form_glycol_dehydration_data_entry";
import Form_sales_gas_compressor_emission_factors_data_entry from "./components/AddData/form_sales_gas_compressor_emission_factors_data_entry";
import Form_sales_gas_compressor_energy_use_data_entry from "./components/AddData/form_sales_gas_compressor_energy_use_data_entry";
import Form_sales_gas_compressor_fugitives_data_entry from "./components/AddData/form_sales_gas_compressor_fugitives_data_entry";
import Form_sales_gas_compressor_ghg_composition_data_entry from "./components/AddData/form_sales_gas_compressor_ghg_composition_data_entry";
import Form_stabilizer_heater_produced_gas_molar_fraction_data_entry from "./components/AddData/form_stabilizer_heater_produced_gas_molar_fraction_data_entry";
import Form_stablizer_emission_data_entry from "./components/AddData/form_stablizer_emission_data_entry";
import Form_storage_stabilization_heater_emission_factors_data_entry from "./components/AddData/form_storage_stabilization_heater_emission_factors_data_entry";
import Form_storage_vapor_composition_data_entry from "./components/AddData/form_storage_vapor_composition_data_entry";
import Form_venting_data_entry from "./components/AddData/form_venting_data_entry";
import Stabilizer_fugitive_emissions from "./components/AddData/stabilizer_fugitive_emissions";
import Tank_fugitives from "./components/AddData/tank_fugitives";
import SeparatorFuelConsumption from "./components/AddData/SeparatorFuelConsumption";
import SeparatorIO from "./components/AddData/SeparatorIO";
import WaterTankFlashComposition from "./components/AddData/WaterTankFlashComposition";
import { loadUser } from "./components/actions/userAction";
import store from "./store";
import LoginSignUp from "./components/layout/auth/LoginSignUp";
import Highlights from "./components/highlights/Highlights";
import Preview from "./components/dashboard/DataPerDay";
import NotFound from "./components/layout/Not Found/NotFound";

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginSignUp />} />
        <Route path="/" element={<Navigate to="/preview" replace />} />
        <Route path="/preview" element={<Highlights />} />

        {/* pages  */}
        <Route path="/FFADE" element={<Form_flaring_activity_data_entry />} />
        <Route
          path="/FFEFDE"
          element={<Form_flaring_emission_factors_data_entry />}
        />
        <Route
          path="/FFGMFDE"
          element={<Form_flaring_ghg_mass_fraction_data_entry />}
        />
        <Route path="/FGDDE" element={<Form_glycol_dehydration_data_entry />} />
        <Route
          path="/FSGCEFDE"
          element={<Form_sales_gas_compressor_emission_factors_data_entry />}
        />

        <Route
          path="/FSGCEUDE"
          element={<Form_sales_gas_compressor_energy_use_data_entry />}
        />
        <Route
          path="/FSGCFDE"
          element={<Form_sales_gas_compressor_fugitives_data_entry />}
        />
        <Route
          path="/FSGCGCDA"
          element={<Form_sales_gas_compressor_ghg_composition_data_entry />}
        />
        <Route
          path="/FSHPGMFDE"
          element={
            <Form_stabilizer_heater_produced_gas_molar_fraction_data_entry />
          }
        />
        <Route path="/FSEDE" element={<Form_stablizer_emission_data_entry />} />

        <Route
          path="/FSSHEFDE"
          element={
            <Form_storage_stabilization_heater_emission_factors_data_entry />
          }
        />
        <Route
          path="/FSVCDE"
          element={<Form_storage_vapor_composition_data_entry />}
        />
        <Route path="/Venting" element={<Form_venting_data_entry />} />
        <Route path="/SFETF" element={<Stabilizer_fugitive_emissions />} />
        <Route path="/Tankf" element={<Tank_fugitives />} />

        <Route
          path="/SeparatorFConsumption"
          element={<SeparatorFuelConsumption />}
        />
        <Route path="/SeparatorIO" element={<SeparatorIO />} />
        <Route
          path="/WaterTFComposition"
          element={<WaterTankFlashComposition />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
