import React from "react";

function Blink() {
  return (
    <div className="flex justify-evenly flex-wrap ">
      <span className="flex h-3 w-3">
        <span className="animate-ping absolute inline-flex h-4 w-20 rounded-full bg-red-400 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
      </span>
      <span className="flex h-3 w-3">
        <span className="animate-ping absolute inline-flex h-4 w-20 rounded-full bg-yellow-400 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
      </span>
      <span className="flex h-3 w-3">
        <span className="animate-ping absolute inline-flex h-4 w-20 rounded-full bg-green-400 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
      </span>
      <span className="flex h-3 w-3">
        <span className="animate-ping absolute inline-flex h-4 w-20 rounded-full bg-blue-400 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
      </span>
    </div>
  );
}

export default Blink;
