import React from "react";
import { FSHPGMFDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_stabilizer_heater_produced_gas_molar_fraction_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout
        formName={
          "form_stabilizer_heater_produced_gas_molar_fraction_data_entry"
        }title={"Stabilizer Heater Produced Gas MF"}
        dataSet={FSHPGMFDE}
      />
    </PrimaryLayout>
  );
};
export default Form_stabilizer_heater_produced_gas_molar_fraction_data_entry;
