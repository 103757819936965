import axios from "axios";
import moment from "moment";

export const createData = async (entries, url,datalength) => {

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(`create/${url}`, entries, config);
    return ;
  } catch (error) {
    console.log("error");
  }
};

export const fetchData = async (url) => {
  try {
    const { data } = await axios.get(`fetchData/${url}`);
    return data;
  } catch (error) {
  }
};

export const fetchReport = async (range) => {
  try {
    // const { datas } = await axios.post(`fetchData/${url}/${data.date_of_use}`,data);
    return 
  } catch (error) {
    console.log("error");
  }
};

export const fetchDataByDate = async (type, date) => {
  try {
    const response = await axios.get(`fetchData/data-by-date/${type}/${date}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching data by date: ", error);
  }
};

export const fetchDataByDateRange = async (type, start, end) => {
  try {
    const response = await axios.get(
      `fetchData/data-by-date-range/${type}/${start}/${end}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data by date range: ", error);
  }
};

export const sumReports = async () => {
  try {
    const sumResponse = await axios.get(`fetchData/sumreport`);
    return sumResponse.data
  } catch (error) {
    console.error("Error fetching data by date range: ", error);
  }
};
export const fetchInitialData = async () => {
  const currentDate = moment().format("YYYY-MM-DD");
  const nextMonthDate = moment(currentDate).add(1, "month");
  const formattedNextMonthDate = nextMonthDate.format("YYYY-MM-DD");


const lastMonthDate = moment(currentDate).subtract(1, "month");
const formattedLastMonthDate = lastMonthDate.format("YYYY-MM-DD");

  try {
    const response1 = await axios.get(
      `fetchData/data-by-date/ghg/${currentDate}`
    );
    const response2 = await axios.get(
      `fetchData/data-by-date-range/ghg/${formattedLastMonthDate}/${currentDate}`
    );
    const response3 = await axios.get(
      `fetchData/data-by-date/source/${currentDate}`
    );
    const response4 = await axios.get(
      `fetchData/data-by-date-range/source/${formattedLastMonthDate}/${currentDate}`
    );
    return [response1.data, response2.data, response3.data, response4.data];
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error;
  }
};
