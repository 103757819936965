import React, { useEffect, useState } from "react";
import Select from "react-select";
import { fetchData } from "../../utilis/api";

const Input = (props) => {
  const { name, type, value, setData, setError, color, data } = props;
  const handleSelectChange = (e) => {
    if (e.label) {
      setData({ ...data, [name]: e.value });
    } else {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      paddingLeft: "12px",
      height: "40px",
      backgroundColor: "white",
      borderColor: state.isFocused ? "transparent" : "#DDDDDD",
      boxShadow: state.isFocused ? "none" : "red",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "",
      color: "black",
    }),
  };

  // Option data
  const [options, setOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const fixedOptions = [
    { value: "type-1", label: "Natural gas" },
    { value: "type-2", label: "Diesel" },
    { value: "type-3", label: "Residual fuel" },
  ];
  useEffect(() => {
    let isMounted = true;

    // Simulating API call to fetch options
    fetchData("form_flaring_emission_factors_data_entry")
      .then((fetchedOptions) => {
        if (isMounted) {
          const transformedData = fetchedOptions.map(({ FEF_ID }) => ({
            value: FEF_ID,
            label: ` ${FEF_ID}`,
          }));
          setOptions(transformedData);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      // Set isMounted to false when the component is unmounted
      isMounted = false;
    };
  }, []);

  return (
    <>
      {" "}
      {type == "number" ? (
        <input
          style={{ height: "40px", color: "black" }}
          type={"number"}
          name={name}
          min={0}
          value={value}
          className={` text-${color}   p-2   md:w-60 form-inputs focus:outline-none`}
          onChange={handleSelectChange}
        ></input>
      ) : type == "date" ? (
        <input
          type={type}
          onChange={handleSelectChange}
          className={`form-inputs p-4 es:w-236 md:w-60 focus:outline-none `}
          name={name}
          min="2018-01-01"
          max="2027-12-31"
        />
      ) : type == "fixed" ? (
        <Select
          placeholder={isLoading ? "Loading" : "Select fuel type"}
          name={name}
          options={fixedOptions}
          value={value}
          className={`text-${color}  form-inputs es:w-236  md:w-60 focus:outline-none  bg-white`}
          styles={customStyles}
          onChange={handleSelectChange}
        />
      ) : (
        <Select
          placeholder={isLoading ? "Loading" : "Select an ID"}
          name={name}
          options={options}
          value={value}
          className={`text-${color}  form-inputs es:w-236  md:w-60 focus:outline-none bg-white pt-0`}
          styles={customStyles}
          onChange={handleSelectChange}
        />
      )}
    </>
  );
};

export default Input;
