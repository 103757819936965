import axios from "axios";

import { DatePicker } from "antd";
import dayjs from "dayjs";
import { fetchDataByDate, fetchDataByDateRange } from "../../utilis/api";
const { RangePicker } = DatePicker;
export const DataPerDay = (props) => {
  const { type, fetchData } = props;

  // setDay({
  //   ...day,
  //   date: dateStrings,
  // });

  const onChange = (date, dateStrings) => {
    if (date) {
      fetchData(type, dateStrings);
    }
  };
  return (
    <DatePicker
      presets={[
        {
          label: "Yesterday",
          value: dayjs().add(-1, "d"),
        },
        {
          label: "Last Week",
          value: dayjs().add(-7, "d"),
        },
        {
          label: "Last Month",
          value: dayjs().add(-1, "month"),
        },
      ]}
      onChange={onChange}
    />
  );
};
export const DataPerRangeDay = (props) => {
  const { type, fetchData } = props;

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  const onRangeChange = (dates, dateStrings) => {
    
    if (dates) {
      fetchData(type, dateStrings[0], dateStrings[1]);
    }
  };
  return (
    <RangePicker
      suffixIcon={<img className="" src="/date.svg" alt="Bottom Image" />} // Customize the arrow icon
      clearIcon={<img className="" src="/date.svg" alt="Bottom Image" />} // Customize the date icon
      allowClear={true}
      style={{
        height: "32px",
        background: "#ffffff",
        border: "1px solid #DDDDDD",
        borderRadius: "2px",
      }}
      presets={rangePresets}
      onChange={onRangeChange}
    />
  );
};


