import React from "react";

const DigitalRead = ({ number }) => {
  const containerStyle = {
    fontFamily: "Audiowide, sans-serif",
    fontWeight: "bold",
    fontSize: "1.2em",
    whiteSpace: "nowrap",
  };

  const unitStyle = {
    fontFamily: "sans-serif",
    fontWeight: "lighter",
    fontSize: "0.8em",
    color: "#999999",
    verticalAlign: "sub",
  };
  const numSub = {
    fontWeight: "lighter",
    fontSize: "0.8em",
    color: "#999999",
    verticalAlign: "sub",
  };

  const formatNumber = (num) => {
    const numString = num
      .toString()
      .padStart(getDigitCount(num) > 6 ? getDigitCount(num) : 7, 0);
    const formattedNumber = numString.split("").map((digit, index) => (
      <span
        key={index}
        style={index < 7 - getDigitCount(number) ? { color: "#999999" } : null}
      >
        {digit}
      </span>
    ));
    return formattedNumber;
  };

  const formattedNumber = formatNumber(number);
 
  const repetitionCount =
    getDigitCount(number) > 6 ? getDigitCount(number) - 6 : 0;
  return (
    <div style={containerStyle}>
      {getDigitCount(number) > 6 ? Array.from({ length: repetitionCount }).map((_, index) => (
        <React.Fragment key={index}>
          {formattedNumber.slice(index, index + 1)}
          {index % 3 === 2 && index < repetitionCount - 1 && <span>,</span>}
        </React.Fragment>
      )):formattedNumber.slice(0, 1)}
      <span style={{ color: getDigitCount(number) <= 6 ? "#DDDDDD" : "" }}>
        ,
      </span>
      {formattedNumber.slice(-6, -3)}
      <span style={{ color: getDigitCount(number) <= 3 ? "#DDDDDD" : "" }}>
        ,
      </span>
      {formattedNumber.slice(-3)}
      <span style={unitStyle}>
        (KgCO<span style={numSub}>2</span>eq)
      </span>
    </div>
  );
};

const getDigitCount = (num) => {
  if (num === 0) {
    return 1; // Special case for zero
  }
  return Math.floor(Math.log10(Math.abs(num))) + 1;
};

export default DigitalRead;
