import React from "react";
import { FSSHEFDE } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Form_storage_stabilization_heater_emission_factors_data_entry = () => {
  return (
    <PrimaryLayout>
      <CommonLayout
        formName={
          "form_storage_stabilization_heater_emission_factors_data_entry"
        } title={"Storage Stabilization Heater Emission Factors"}
        dataSet={FSSHEFDE}
      />
    </PrimaryLayout>
  );
};
export default Form_storage_stabilization_heater_emission_factors_data_entry;
