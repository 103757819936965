import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer } from "./components/reducers/userReducer";

import { appReducer } from "./components/reducers/appReducer";

const reducer = combineReducers({
  user: userReducer,
  app: appReducer,
});

const initialState = {
 app:{
  screenSize: undefined,
  currentColor:  localStorage.getItem("colorMode"),
  currentMode: localStorage.getItem("themeMode"),
  themeSettings: false,
  activeMenu: true,
 }
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;



