import React from "react";
import { SIO } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const SeparatorIO = () => {
  return (
    <PrimaryLayout>
      <CommonLayout formName={"separatorIO"} title={"Separator IO data"} dataSet={SIO} />
    </PrimaryLayout>
  );
};
export default SeparatorIO;
