import React from "react";
import { SFC } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const SeparatorFuelConsumption = () => {
  return (
    <PrimaryLayout>
      <CommonLayout formName={"separatorFuelConsumption"} title={"Separator Fuel Consumption"} dataSet={SFC} />
    </PrimaryLayout>
  );
};
export default SeparatorFuelConsumption;
