import React from "react";
import { Tankf } from "../../data/dummy-files";
import CommonLayout from "./commonLayout";
import PrimaryLayout from "../layout/PrimaryLayout";

const Tank_fugitives = () => {
  return (
    <PrimaryLayout>
      <CommonLayout formName={"tank_fugitives"} title={"Tank Fugitives"} dataSet={Tankf} />
    </PrimaryLayout>
  );
};
export default Tank_fugitives;
