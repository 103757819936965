import React, { Fragment, useRef, useState, useEffect } from "react";

import "./LoginSignUp.css";
import Loader from "../Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { CiMail } from "react-icons/ci";

import { HiOutlineLockOpen } from "react-icons/hi";
import { GiMonkFace } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { login, clearErrors, register } from "../../actions/userAction";
import MetaData from "../MetaData";
import { useLocation } from 'react-router-dom';

const LoginSignUp = () => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const loginTab = useRef(null);
  const registerTab = useRef(null);
  const switcherTab = useRef(null);

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });

  const { name, email, password } = user;

  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(login(loginEmail, loginPassword));
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    dispatch(register({ name, email, password }));
  };

  const registerDataChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      navigate(from.pathname);
    }
  }, [dispatch, error, isAuthenticated]);

  const switchTabs = (e, tab) => {
    if (tab === "login") {
      switcherTab.current.classList.add("shiftToNeutral");
      switcherTab.current.classList.remove("shiftToRight");

      registerTab.current.classList.remove("shiftToNeutralForm");
      loginTab.current.classList.remove("shiftToLeft");
    }
    if (tab === "register") {
      switcherTab.current.classList.add("shiftToRight");
      switcherTab.current.classList.remove("shiftToNeutral");

      registerTab.current.classList.add("shiftToNeutralForm");
      loginTab.current.classList.add("shiftToLeft");
    }
  };

  return (
    <Fragment>
      <MetaData title={"login"} />
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <>
            <div className="LoginSignUpContainer">
              <div className="LoginSignUpBox ">
                <div className="header-ghg">
                  <img src="/header.svg" alt="Bottom Image" />
                  <h1>Welcome to save the earth</h1>
                </div>
                <div className="switches-tab">
                  <div className="login_signUp_toggle">
                    <p onClick={(e) => switchTabs(e, "login")}>Login</p>
                    <p onClick={(e) => switchTabs(e, "register")}>Register</p>
                  </div>
                  <div className="switchingTab">
                    <button  ref={switcherTab}></button>
                  </div>
                </div>
                <form
                  className="loginForm"
                  ref={loginTab}
                  onSubmit={loginSubmit}
                >
                  <p className=" w-full  form-input">  <img src="/email.svg" alt="Bottom Image" /> Email</p>
                
                  <div className="loginEmail">
                    <input
                      type="email"
                      placeholder="Email"
                      required
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                    />
                  </div>
                  <p className=" w-full form-input">  <img src="/password.svg" alt="Bottom Image" /> Password</p>
                  <div className="loginPassword">
                    <input
                      type="password"
                      placeholder="Password"
                      required
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                    />
                  </div>
                  <input type="submit" value="Login" className="loginBtn" />
                </form>
                <form
                  className="signUpForm"
                  ref={registerTab}
                  encType="multipart/form-data"
                  onSubmit={registerSubmit}
                >
                   <p className=" w-full form-input">  <img src="/name.svg" alt="Bottom Image" /> Name</p>
                  <div className="signUpName">
                    <input
                      type="text"
                      placeholder="Name"
                      required
                      disabled
                      name="name"
                      value={name}
                      onChange={registerDataChange}
                    />
                  </div>
                  <p className=" w-full form-input">  <img src="/email.svg" alt="Bottom Image" /> Email</p>
                  <div className="signUpEmail">
                    <input
                      type="email"
                      placeholder="Email"
                      required
                      name="email"
                      disabled
                      value={email}
                      onChange={registerDataChange}
                    />
                  </div>
                  <p className=" w-full form-input">  <img src="/password.svg" alt="Bottom Image" /> Password</p>
                  <div className="signUpPassword">
                    <input
                      type="password"
                      placeholder="Password"
                      required
                      name="password"
                      value={password}
                      disabled
                      onChange={registerDataChange}
                    />
                  </div>
                  <input type="submit" value="Register" disabled className="signUpBtn" />
                </form>
              </div>
            </div>
            <img
              className="absolute bottom-0 left-0 z-index-1 w-full "
              src="/background.svg"
              alt="Bottom Image"
              style={{ zIndex: 1 }}
            />
          </>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LoginSignUp;
