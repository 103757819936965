import React, { useState } from "react";
import { createData, fetchData } from "../../utilis/api";
import Header from "../layout/header/FormHeader";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Blink from "../layout/header/Blink";
import Select from "react-select";
import Input from "./Input";
import SUmUp from "../summary/SUmUp";
const CommonLayout = (props) => {
  const { formName, dataSet, title } = props;
  const initialData = dataSet.reduce((acc, item) => {
    acc[item.name] = item.type === "number" ? 0 : null;
    return acc;
  }, {});

  const { currentColor } = useSelector((state) => state.app);

  const [data, setData] = useState(initialData);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    // Check if any field in the data object is still set to its initial value (null for text fields)
    if (Object.values(data).some((value) => value === null)) {
      setError("Please fill in all fields.");
    } else {
      // All fields have been updated, proceed with API request
      createData(data, formName).catch((err) => {
        setError(err?.toString());
      });
      navigate("/");
    }
  };
  return (
    <>
      <SUmUp />
      <div className=" md:m-10    bg-white rounded-3xl ">
        {/* <Blink /> */}
        {/* <Header category="Page" title="Emmission Datas" /> */}
        <div className="flex justify-center flex-wrap ">
          <div className="flex m-4 flex-wrap ">
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg p-6 rounded-2xl  ">
              <div className="flex justify-between items-center gap-2">
                <p className="text-xl font-semibold">Data Entry for {title}</p>
              </div>

              <div className="mt-10 flex flex-col justify-start items-start">
                <div className="flex flex-col ">
                  {dataSet.map((item, index) => (
                    <div
                      key={item.title}
                      className="flex justify-between flex-col es:flex-row mt-4 "
                    >
                      <div
                        className="flex gap-3 mr-3"
                        style={{ minWidth: "context" }}
                      >
                        {/* <button
                      type="button"
                      style={{
                        color: item.iconColor,
                        backgroundColor: item.iconBg,
                      }}
                      className="text-2xl rounded-lg h-8 w-6 mt-2 hover:drop-shadow-xl"
                    >
                      {item.icon}
                    </button> */}
                        <button
                          type="button"
                          className="text-2xl rounded-lg h-8 w-6 mt-2 hover:drop-shadow-xl "
                        >
                          <img
                            className=""
                            src="/shape.svg"
                            alt="Bottom Image"
                          />
                        </button>
                        <div className="mb-2 mr-2">
                          <p className="text-md font-semibold">{item.title}</p>
                          <p className="text-sm text-gray-400">{item.desc}</p>
                        </div>
                      </div>
                      <Input
                        name={item.name}
                        type={item.type}
                        value={data[index]}
                        setData={setData}
                        setError={setError}
                        color={item.pcColor}
                        data={data}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {error && (
          <p className="flex align-center  justify-center text-red-900">
            Please fill in all fields.
          </p>
        )}
        <div className="flex justify-center  font-bold   text-2xl pt-12 mb-24  ">
          {" "}
          <button
            onClick={handleSubmit}
            className=" sub-btn w-32"
            // disabled={Object.values(data).some((value) => value === null)}
          >
            Submit
            {error && (
              <span className="tooltip">
                <span className="tooltiptext">{error}</span>
              </span>
            )}
          </button>
          {/* <button
          onClick={() => {
            console.log(fetchData(formName));
          }}
          className=" ml-6 px-6 rounded-md "
          style={{ backgroundColor: currentColor }}
        >
          Get Data
        </button> */}
        </div>
      </div>
    </>
  );
};
export default CommonLayout;
